const en = {
  Booking: 'Booking',
  'Go back': 'Go back',
  'By making a booking, you agree to have read and understood our Terms of Use and Privacy Policy': 'By making a booking, you agree to have read and understood our Terms of Use and Privacy Policy',
  Location: 'Location',
  'Select location': 'Select location',
  'Select the location you wish to book at': 'Select the location you wish to book at',
  Service: 'Service',
  'The minimum time of booking is hours hours!': 'The minimum time of booking is {hours} hours!',
  Lane: 'Lane',
  Table: 'Table',
  Hour: 'Hour',
  Hours: 'Hours',
  'NB! An advance payment of €50 is required for the packages.': 'NB! An advance payment of €50 is required for the packages.',
  Packages: 'Packages',
  Package: 'Package',
  Quantity: 'Quantity',
  'Select a package': 'Select a package',
  'What is included in the package?': 'What is included in the package?',
  'Select a service': 'Select a service',
  Date: 'Date',
  Time: 'Time',
  'Select a date to see availability!': 'Select a date to see availability!',
  'There are no times available on this day.': 'There are no times available on this day.',
  'Previous day': 'Previous day',
  'Next day':  'Next day',
  'Select a time': 'Select a time',
   Contacts: 'Contacts',
  'First name and family name': 'First name and family name',
  'E-mail address': 'E-mail address',
  Phone: 'Phone',
  'Gift card': 'Gift card',
  'I have a gift card': 'I have a gift card',
  'Discount code': 'Discount code',
  'I have a discount code': 'I have a discount code',
  'Please check the discount code.': 'Please check the discount code.',
  'The discount code is valid': 'The discount {code} is valid',
  'The gift card code is valid': 'The gift card {code} is valid',
  'Please check the gift card code.': 'Please check the gift card code.',
  'You have already made a booking in the last hour!': 'You have already made a booking in the last hour!',
  Check: 'Check',
  'Enter the gift card code': 'Enter the gift card code',
  'Enter the discount code': 'Enter the discount code',
  'An advance payment of €50 is required to book a package.': 'An advance payment of €50 is required to book a package.',
  'terms and conditions': 'terms and conditions',
  'I agree with the general terms and conditions of purchase': 'I agree with the <a href=\'' + 'https://kuulsaal.ee/en/about-us/booking-conditions/' + '\' target=\'_blank\'>' + 'terms and conditions' + '</a> of purchase',
  'Book': 'Book',
  'Confirm the booking': 'Confirm the booking',
  'Redirecting to payment page, please wait': 'Redirecting to payment page, please wait',
  'Thank you for your booking!': 'Thank you for your booking!',
  'A booking confirmation has been sent to your e-mail address.': 'A booking confirmation has been sent to your e-mail address.',
  'Back to homepage': 'Back to homepage',
  'Are you sure you wish to cancel your booking?': 'Are you sure you wish to cancel your booking?',
  'Order number:': 'Order number:',
  'No, do not cancel': 'No, do not cancel',
  'Yes, cancel booking': 'Yes, cancel booking',
  'The booking has been cancelled.': 'The booking has been cancelled.',
  'Your booking has been cancelled. If you have made an advance payment and there is more than 72 hours until your booking, the refund will be made to your bank account as soon as possible.': 'Your booking has been cancelled. If you have made an advance payment and there is more than 72 hours until your booking, the refund will be made to your bank account as soon as possible.',
  'There was a problem with the payment!': 'There was a problem with the payment!',
  'Your payment could not be processed. If you think this was a mistake, please contact our customer support.': 'Your payment could not be processed.<br/>If you think this was a mistake, please contact our <a target="_blank" href="https://kuulsaal.ee/en/contact/kesklinn/">customer support</a>.',
  'terms of use': 'terms of use',
  'privacy policy': 'privacy policy',
  'By booking the time you agree that you have read and got acquainted with us': 'By booking an appointment you agree and that you have read our <a href=\'' + 'https://kuulsaal.ee/en/about-us/booking-conditions/' + '\' target=\'_blank\'>terms of use</a> and <a href=\'' + 'https://kuulsaal.ee/en/about-us/privacy-policy/' + '\' target=\'_blank\'>privacy policy</a>.',
  'If you wish to pay under the warranty, please contact us to make a reservation.': 'If you wish to pay under the warranty, please contact us to make a reservation.',
  'It seems that your are trying to book a larger order. To get the best offer, please contact us!': 'It seems that your are trying to book a larger order. To get the best offer, please contact us!',
  'hours': 'hours',
  'Kuulsaal - Reservation': 'Kuulsaal - Reservation',
}
export default en;