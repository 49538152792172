
const ru = {
  Booking: 'Бронирование',
  'Go back': 'Назад',
  'By making a booking, you agree to have read and understood our Terms of Use and Privacy Policy': 'Оформляя бронирование, Вы подтверждаете, что прочитали и поняли наши условия пользования и политику конфиденциальности.',
  Location: 'Местонахождение',
  'Select location': 'Выберите место',
  'Select the location you wish to book at': 'Выберите место, где хотите оформить бронирование',
  Service: 'Услуга',
  'The minimum time of booking is hours hours!': 'Минимальное количество часов бронирования: {hours}.',
  Lane: 'Дорожка',
  Table: 'Стол',
  Hour: 'Час',
  Hours: 'Часа/часов',
  'NB! An advance payment of €50 is required for the packages.': 'Примечание: При заказе пакета требуется предоплата в размере 50 €.',
  Packages: 'Пакеты',
  Package: 'Пакет',
  Quantity: 'Количество',
  'Select a package': 'Выберите пакет',
  'What is included in the package?': 'Что входит в пакет?',
  'Select a service': 'Выберите услугу',
  Date: 'Дата',
  Time: 'Время',
  'Select a date to see availability!': 'Выберите дату, чтобы узнать о наличии свободных мест!',
  'There are no times available on this day.': 'Свободных мест в выбранную дату нет.',
  'Previous day': 'Предыдущий день',
  'Next day':  'Следующий день',
  'Select a time': 'Выберите время',
  Contacts: 'Контакты',
  'First name and family name': 'Имя и фамилия',
  'E-mail address': 'Адрес эл. почты',
  Phone: 'Телефон',
  'Gift card': 'Подарочная карта',
  'I have a gift card': 'У меня есть подарочная карта',
  'Discount code': 'Код скидки',
  'I have a discount code': 'У меня есть код скидки',
  'Please check the discount code.': 'Пожалуйста, проверьте код скидки.',
  'The discount code is valid': 'Скидка {code} действительна.',
  'The gift card code is valid': 'Подарочная карта {code} действительна.',
  'Please check the gift card code.': 'Пожалуйста, проверьте код подарочной карты.',
  'You have already made a booking in the last hour!': 'Вы уже сделали бронирование за последний час',
  Check: 'Проверить',
  'Enter the gift card code': 'Введите код подарочной карты',
  'Enter the discount code': 'Введите код скидки',
  'An advance payment of €50 is required to book a package.': 'Для бронирования пакета требуется предоплата в размере 50€.',
  'terms and conditions': 'условиями и условиями',
  'I agree with the general terms and conditions of purchase': 'Согласиться с общими <a href=\'' + 'https://kuulsaal.ee/ru/%d0%be-%d0%bd%d0%b0%d1%81/%d1%83%d1%81%d0%bb%d0%be%d0%b2%d0%b8%d1%8f-%d0%b1%d1%80%d0%be%d0%bd%d0%b8%d1%80%d0%be%d0%b2%d0%b0%d0%bd%d0%b8%d1%8f/' + '\' target=\'_blank\'>' + 'условиями и условиями' + '</a> покупки ',
  'Book': 'Бронировать',
  'Confirm the booking': 'Подтвердить бронирование',
  'Redirecting to payment page, please wait': 'Производится переход к оплате. Пожалуйста, подождите',
  'Thank you for your booking!': 'Спасибо за бронирование!',
  'A booking confirmation has been sent to your e-mail address.': 'Электронное письмо с подтверждением бронирования отправлено Вам на электронную почту',
  'Back to homepage': 'На главную',
  'Are you sure you wish to cancel your booking?': 'Вы уверены, что хотите отменить бронирование?',
  'Order number:': 'Номер заказа:',
  'No, do not cancel': 'Нет, не отменить',
  'Yes, cancel booking': 'Да',
  'The booking has been cancelled.': 'Бронирование отменено',
  'Your booking has been cancelled. If you have made an advance payment and there is more than 72 hours until your booking, the refund will be made to your bank account as soon as possible.': 'Ваше бронирование отменено. Если Вы внесли предоплату и до забронированного срока осталось более 72 часов, возврат средств будет произведен на Ваш счет при первой возможности.',
  'There was a problem with the payment!': 'Проблема с платежом!',
  'Your payment could not be processed. If you think this was a mistake, please contact our customer support.': 'Ваш платеж не прошел.<br/>Если Вы считаете, что это ошибка, обратитесь в нашу <a target="_blank" href="https://kuulsaal.ee/ru/%d0%ba%d0%be%d0%bd%d1%82%d0%b0%d0%ba%d1%82/%d1%86%d0%b5%d0%bd%d1%82%d1%80-%d0%b3%d0%be%d1%80%d0%be%d0%b4%d0%b0/">службу поддержки</a>.',
  'terms of use': 'Условия эксплуатации',
  'privacy policy': 'политика конфиденциальности',
  'By booking the time you agree that you have read and got acquainted with us': 'Записываясь на прием, вы соглашаетесь с тем, что прочитали и прочитали наши <a href=\'' + 'https://kuulsaal.ee/ru/%d0%be-%d0%bd%d0%b0%d1%81/%d1%83%d1%81%d0%bb%d0%be%d0%b2%d0%b8%d1%8f-%d0%b1%d1%80%d0%be%d0%bd%d0%b8%d1%80%d0%be%d0%b2%d0%b0%d0%bd%d0%b8%d1%8f/' + '\' target=\'_blank\'>Условия эксплуатации</a> и <a href=\'' + 'https://kuulsaal.ee/ru/%d0%be-%d0%bd%d0%b0%d1%81/%d0%bf%d0%be%d0%bb%d0%b8%d1%82%d0%b8%d0%ba%d0%b0-%d0%ba%d0%be%d0%bd%d1%84%d0%b8%d0%b4%d0%b5%d0%bd%d1%86%d0%b8%d0%b0%d0%bb%d1%8c%d0%bd%d0%be%d1%81%d1%82%d0%b8/' + '\' target=\'_blank\'>политика конфиденциальности</a>.',
  'If you wish to pay under the warranty, please contact us to make a reservation.': 'Если вы хотите произвести оплату по гарантии, пожалуйста, свяжитесь с нами, чтобы сделать заказ.',
  'It seems that your are trying to book a larger order. To get the best offer, please contact us!': 'Похоже, вы пытаетесь заказать более крупный заказ. Свяжитесь с нами, чтобы получить лучшее предложение!',
  'hours': 'часы',
  'Kuulsaal - Reservation': 'Kuulsaal - Бронирование'
}
export default ru;
