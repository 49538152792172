<template>
  <div class="container">
    <div class="row">
      <div id="booking" class="col-xl-5 col-lg-7 col-md-8 mx-auto">
        <h1>{{ $t('Booking') }}</h1>
        <div class="steps">
          <div class="step-complete two-fifth"></div>
          <div class="step-data">
            <p>2/5</p>
          </div>
          <div class="step-incomplete two-fifth"></div>
        </div>
        <form class="details needs-validation" action="" novalidate>
          <h2>{{ $t('Service') }}</h2>
          <div class="radio-button" v-for="(aservice, index) in availableStandardServices" :key="index">
            <input class="radio-custom" type="radio" :checked="aservice.id === service"  @click="setServicePrep(aservice)">
            <label for="radio-10" class="radio-custom-label"  @click="setServicePrep(aservice)">{{ aservice.name }}</label>
            <div :class="{'d-none':aservice.id !== service}">
              <p>
                {{ $t('The minimum time of booking is hours hours!', {hours: getMinimumServiceTotal(aservice.minimum_booking_total)}) }}
              </p>
              <div class="col-md-6">
                <div class="input-group quantity-container my-3">
                  <button class="decrease btn btn-outline-secondary" type="button" @click="decrementAmount()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
                      <path fill-rule="evenodd" d="M2 7.75A.75.75 0 012.75 7h10a.75.75 0 010 1.5h-10A.75.75 0 012 7.75z"></path>
                    </svg>
                  </button>
                  <input type="text" class="quantity-amount form-control" name="" :value=" amount + ' '+quantityName" />
                  <button class="increase btn btn-outline-secondary" type="button" @click="incrementAmount()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18">
                      <path fill-rule="evenodd" d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"></path>
                    </svg>
                  </button>
                </div>
                <div class="input-group quantity-container">
                  <button class="decrease btn btn-outline-secondary" type="button" @click="decrementDuration()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
                      <path fill-rule="evenodd" d="M2 7.75A.75.75 0 012.75 7h10a.75.75 0 010 1.5h-10A.75.75 0 012 7.75z"></path>
                    </svg>
                  </button>
                  <input type="text" class="quantity-amount form-control" name="" :value="duration + ' ' + $t('hours')" />
                  <button class="increase btn btn-outline-secondary" type="button" @click="incrementDuration()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18">
                      <path fill-rule="evenodd" d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <p v-if="amount >= aservice.maximum_booking_total">
                {{ $t('It seems that your are trying to book a larger order. To get the best offer, please contact us!') }}
              </p>
            </div>
          </div>
          <!-- <div class="radio-button" @click="setService('Piljard')">
            <input id="radio-20" class="radio-custom" name="radio-group-service" type="radio">
            <label for="radio-20" class="radio-custom-label">Piljard</label>
          </div> -->
          <div class="radio-button" @click="showPlanServices()">
            <input id="radio-30" class="radio-custom" name="radio-group-service" type="radio" :checked="this.isPackage">
            <label for="radio-30" class="radio-custom-label"> {{ $t('Packages') }}</label>
          </div>
          <p>{{ $t('NB! An advance payment of €50 is required for the packages.') }}</p>
          <div :class="{ 'd-none': !planServicesShow }">
            <div class="row">
              <div class="col-md-7">
                <div class="form-group">
                  <label for="text" class="form-label">{{ $t('Package') }}</label>
                  <select class="form-select" id="package-select" :class="{error: service ? false : true}" v-model="packageType" @change="updatePackage()">
                    <option value="" selected disabled>{{ $t('Select a package') }}</option>
                    <option :value="aservice.id" v-for="(aservice, index) in availablePlanServices" :key="index" :selected="aservice.id === service">{{ aservice.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <label for="text" class="form-label">{{ $t('Quantity') }}</label>
                <div class="input-group quantity-container">
                  <button class="decrease btn btn-outline-secondary" type="button" @click="decrementAmount()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
                      <path fill-rule="evenodd" d="M2 7.75A.75.75 0 012.75 7h10a.75.75 0 010 1.5h-10A.75.75 0 012 7.75z"></path>
                    </svg>
                  </button>
                  <input type="number" class="quantity-amount form-control" name="" :value="amount"/>
                  <button class="increase btn btn-outline-secondary" type="button" @click="incrementAmount()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18">
                      <path fill-rule="evenodd" d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="col-md-12">
                <div class="accordion accordion-flush" id="accordion" :class="{'d-none': this.service ? false : true}">
                  <div class="accordion-item">
                    <div class="accordion-header" id="flush-headingOne">
                      <a class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        {{ $t('What is included in the package?') }}
                      </a>
                    </div>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordion">
                      <div class="accordion-body">
                        <p><span v-html="serviceDescription"></span></p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <p>NB! Pakettide tellimisel tuleb tasuda ettemaks 50€.</p> -->
              </div>
            </div>
            <p v-if="amount >= maxTotalAmount">
              {{ $t('It seems that your are trying to book a larger order. To get the best offer, please contact us!') }}
            </p>
          </div>
            <template v-if="additionalQuestion">
              <h2>{{ additionalQuestion.question }}</h2>
              <div class="last-section">
                <template  v-for="(answer, index) in additionalQuestion.answers" :key="index">
                  <div class="radio-button"  @click="setAnswerPrep(answer)">
                    <input class="radio-custom" type="radio" :checked="additionalQuestionAnswer === answer">
                    <label for="radio-1" class="radio-custom-label">{{ answer }}</label>
                  </div>
                </template>
              </div>
            </template>
            <div class="row">
              <div class="col-6 text-center">
                <router-link to="/">
                  <a href="#" class="btn btn-secondary">{{ $t('Go back') }}</a>
                </router-link>
              </div>
              <div class="col-6 text-center">
                <a href="#package-select" class="btn btn-primary" :class="{disabled: inputCheck }" @click="checkInput()">{{ $t('Select a service') }}</a>
              </div>
            </div>
          
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'

export default{
  mounted () {
    this.getAvailableStandardServices()
    this.getAvailablePlanServices()
    this.setIsLoading(false)
    
    if(!this.location){
      this.$router.push('/')
    }
   
    if(!this.isPackage && !this.service && this.availableStandardServices.length > 0 ){
      this.setServicePrep(this.availableStandardServices[0])
    }

    if(this.isPackage){
      this.planServicesShow = true
    }
    
  },

  data () {
    return {
      packageType: "",
      show: true,
      planServicesShow: false,
      serviceDescription: "",
      maxTotalAmount: 0,
      bookingTotalHours: 0,
      serviceTotalCheck: true
    }
  },

  computed: {
    ...mapState({
      availableStandardServices: state => state.available_standard_services,
      availablePlanServices: state => state.available_plan_services,
      service: state => state.service,
      amount: state => state.amount,
      duration: state => state.duration,
      dessert: state => state.dessert,
      location: state => state.location,
      isPackage: state => state.is_package,
      additionalQuestionAnswer: state => state.additional_question_answer,
      additionalQuestion: state => state.additional_question,
      quantityName: state => state.quantity_name,
      locale: state => state.locale
    }),
    inputCheck () {
      let additionQuestionCheck = false;
      if(this.service){
        if(this.additionalQuestion.question && this.additionalQuestionAnswer) {
          additionQuestionCheck = false
        } else if (!this.additionalQuestion.question && !this.additionalQuestionAnswer) {
          additionQuestionCheck = false
        } else {
          additionQuestionCheck = true
        }
      }

      if (this.isPackage) {
        return additionQuestionCheck || !this.service
      } else {
        return additionQuestionCheck || !this.serviceTotalCheck || !this.service
      }
    },
  },

  watch: {
    availableStandardServices () {
      if(!this.isPackage && !this.service && this.availableStandardServices.length > 0 ){
        this.setServicePrep(this.availableStandardServices[0])
      }
    },
    amount () {
      this.bookingTotalHours = this.amount * this.duration
    },
    duration () {
      this.bookingTotalHours = this.amount * this.duration
    },
    bookingTotalHours () {
      this.serviceTotalCheck = this.getMinimumServiceTotalOfSelectedService() <= this.bookingTotalHours
    },
    service () {
      this.serviceTotalCheck = this.getMinimumServiceTotalOfSelectedService() <= this.bookingTotalHours
    },
    locale (oldVal, newVal) {
      if (oldVal !== newVal) {
        window.location.href = '/';
      }
    }
  },

  methods: {
    ...mapMutations([
      'setService',
      'setPackage',
      'setAmount',
      'setDessert',
      'setIsLoading',
      'decrementAmount',
      'incrementAmount',
      'decrementDuration',
      'incrementDuration',
      'setIsPackage',
      'setDuration',
      'setAdditionalQuestionAnswer',
      'setAdditionalQuestion',
      'setRouteName',
      'setQuantityName'
    ]),

    ...mapActions([
      'getAvailableStandardServices',
      'getAvailablePlanServices'
    ]),
    
    updatePackage(){
      if(this.packageType) {
        this.setService(this.packageType)
        let planService = this.availablePlanServices.find(x => x.id === this.packageType)
        this.serviceDescription = planService.description
        this.maxTotalAmount = planService.maximum_booking_total
        this.setAdditionalQuestion(planService.additional_question)
        this.setAdditionalQuestionAnswer('')
      }else{
        this.packageType = ''
        this.setService('')
        this.setAdditionalQuestion(null)
        this.setAdditionalQuestionAnswer('')
        this.setAmount(1)
        this.setDuration(1)
      }
    },

    setServicePrep(value){
      this.setAdditionalQuestion(value.additional_question)
      this.setAdditionalQuestionAnswer('')
      this.setAmount(1)
      this.setDuration(1)
      this.setQuantityName(value.quantity_name)
      this.setService(value.id)
      this.bookingTotalHours = this.amount * this.duration
      this.planServicesShow = false
      this.setIsPackage(false)
    },

    showPlanServices(){
      this.packageType = ''
      this.setService('')
      this.setAdditionalQuestion(null)
      this.setAdditionalQuestionAnswer('')
      this.setAmount(1)
      this.setDuration(1)
      this.bookingTotalHours = this.amount * this.duration
      this.setIsPackage(true)
      this.planServicesShow = true
    },
    checkInput () {
      if (!this.inputCheck){
        this.setIsLoading(true)
        this.$router.push('step3')
      }
    },
    setAnswerPrep(value){
      if(this.additionalQuestionAnswer === value){
        this.setAdditionalQuestionAnswer('')
      }else{
        this.setAdditionalQuestionAnswer(value)
      }
    },
    getMinimumServiceTotal (minimumServiceTotal) {
      return minimumServiceTotal ? minimumServiceTotal : 1;
    },
    getMinimumServiceTotalOfSelectedService() {
      const service = this.availableStandardServices.find(x => x.id === this.service)
      return service ? (service.minimum_booking_total > 0 ? service.minimum_booking_total : 1) : 1
    }
  }
}
</script>
