import { createStore } from 'vuex'
import axios from 'axios'
import moment from 'moment'

export default createStore({
  state: {
    locale: 'en',
    location: '',
    service: '',
    is_package: false,
    amount: 1,
    duration: 1,
    dessert: '',
    book_date: moment().format("DD.MM.YYYY"),
    book_time: '',
    full_name: '',
    email: '',
    phone: '',
    gift_card_code: '',
    available_locations: [],
    available_standard_services: [],
    available_plan_services: [],
    available_times: [],
    is_booked: false,
    errors: [],
    is_loading: false,
    additional_info: '',
    additional_question_answer: '',
    additional_question: null,
    route_name: '',
    redirect_url: null,
    gift_card_valid: false,
    gift_card_checking: false,
    quantity_name: '',
    discount_code: '',
    discount_code_valid: false,
    discount_code_checking: false,
    has_booking_last_hour: false,
  },
  mutations: {
    setLocation(state, value) {
      state.location = value
    },
    setService(state, value) {
      state.service = value
    },
    setIsPackage(state, value) {
      state.is_package = value
    },
    setDessert(state, value) {
      state.dessert = value
    },
    setBookDate(state, value) {
      state.book_date = value
    },
    setTime(state, value) {
      state.book_time = value
    },
    setFullName(state, value) {
      state.full_name = value
    },
    setEmail(state, value) {
      state.email = value
    },
    setPhone(state, value) {
      state.phone = value
    },
    setGiftCardCode(state, value) {
      state.gift_card_code = value
    },
    setDiscountCode(state, value) {
      state.discount_code = value
    },
    setAvailableLocations(state, value) {
      state.available_locations = value
    },
    setAvailableStandardServices(state, value) {
      state.available_standard_services = value
    },
    setAvailablePlanServices(state, value) {
      state.available_plan_services = value
    },
    setAvailableTimes(state, value) {
      state.available_times = value
    },
    setIsBooked(state, value) {
      state.is_booked = value
    },
    setErrors(state, value) {
      state.errors = value
    },
    setIsLoading(state, value) {
      state.is_loading = value
    },
    setAmount(state, value) {
      state.amount = value
    },
    setDuration(state, value) {
      state.duration = value
    },
    decrementAmount(state) {
      if (state.amount > 1) {
        state.amount--;
      }
    },
    incrementAmount(state) {
      state.amount++;
    },
    decrementDuration(state) {
      if (state.duration > 1) {
        state.duration--;
      }
    },
    incrementDuration(state) {
      state.duration++;
    },
    setAdditionalQuestionAnswer(state, value) {
      state.additional_question_answer = value
    },
    setAdditionalQuestion(state, value) {
      state.additional_question = value
    },
    setRouteName(state, value) {
      state.route_name = value
    },
    setRedirectUrl(state, value) {
      state.redirect_url = value
    },
    setGiftCardValid(state, value) {
      state.gift_card_valid = value
    },
    setGiftCardChecking(state, value) {
      state.gift_card_checking = value
    },
    setDiscountCodeValid(state, value) {
      state.discount_code_valid = value
    },
    setDiscountCodeChecking(state, value) {
      state.discount_code_checking = value
    },
    setQuantityName(state, value) {
      state.quantity_name = value
    },
    setHasBookingLastHour(state, value) {
      state.has_booking_last_hour = value
    },
    setLocale(state, value) {
      state.locale = value
    }
  },
  actions: {
    getAvailableLocations({ state, commit }) {
      axios.get(process.env.VUE_APP_API_URL + '/api/' + state.locale + '/locations').then((response) => {
        commit('setAvailableLocations', response.data)
        commit('setLocation', response.data[0].id)
      })
    },
    getAvailableStandardServices({ state, commit }) {
      if (state.location) {
        axios.get(process.env.VUE_APP_API_URL + '/api/' + state.locale + '/services/' + state.location + '/standard').then((response) => {
          commit('setAvailableStandardServices', response.data)
        })
      }
    },
    getAvailablePlanServices({ state, commit }) {
      if (state.location) {
        axios.get(process.env.VUE_APP_API_URL + '/api/' + state.locale + '/services/' + state.location + '/plan').then((response) => {
          commit('setAvailablePlanServices', response.data)
        })
      }
    },
    getAvailableTimes({ state, commit }) {
      let payload = {
        "date": state.book_date,
        "requested_service": state.service,
        "amount": state.amount,
        "duration": state.duration * 60
      }

      if (state.amount && state.service && state.book_date) {
        axios.post(process.env.VUE_APP_API_URL + '/api/availabletimes', payload).then((response) => {
          commit('setAvailableTimes', response.data)
        })
      }
    },
    checkIfHasBookingLastHour({ state, commit }) {
      commit('setHasBookingLastHour', false);

      axios.get(process.env.VUE_APP_API_URL + '/api/has-orders-last-hour/' + state.email).then((response) => {
        if(response.data.has_made_order_last_hour) {
          commit('setHasBookingLastHour', true);
        }
      });
    },
    storeBooking({ state, commit }) {
      let payload = {
        date: state.book_date,
        requested_service: state.service,
        amount: state.amount,
        requested_timeblock: state.book_time,
        customer_name: state.full_name,
        customer_email: state.email,
        customer_phone: state.phone.replace(/\s/g, ''),
        duration: state.duration * 60,
        additional_info: state.additional_info,
        customer_language: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'et',
        order_meta: {
          additional_question_answer: state.additional_question_answer ? state.additional_question_answer : ''
        }
      }

      if (state.gift_card_valid) {
        payload.giftcard_code = state.gift_card_code
      }
      if (state.discount_code_valid) {
        payload.discount_code = state.discount_code
      }

      axios.post(process.env.VUE_APP_API_URL + '/api/bookings/create', payload)
        .then((response) => {
          if (response.data.status === true) {
            if (response.data.data.payment_required) {
              commit('setRedirectUrl', response.data.data.url)
            }
            commit('setIsBooked', true)
            commit('setIsLoading', false)
          }
        }).catch((error) => {
          commit('setErrors', error.response.data.errors)
          commit('setIsLoading', false)
        })
    },
    giftCardCheck({ state, commit }) {
      commit('setGiftCardChecking', true)
      axios.get(process.env.VUE_APP_API_URL + '/api/giftcard/' + state.gift_card_code)
        .then((response) => {
          commit('setGiftCardValid', response.data.giftcard_valid)
          commit('setGiftCardChecking', false)
        })
    },
    discountCodeCheck({ state, commit }) {
      commit('setDiscountCodeChecking', true)

      let payload = {
        "booking_date": moment(state.book_date, 'DD.MM.YYYY').format(), // in datetime format
        "service_id": state.service,
        "discount_code": state.discount_code
      }

      axios.post(process.env.VUE_APP_API_URL + '/api/discount_code', payload).then((response) => {
        commit('setDiscountCodeValid', response.data.discount_code_valid)
        commit('setDiscountCodeChecking', false)
      })
    },
  },
  modules: {
  }
})
